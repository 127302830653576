export default {
  name: 'card-video',
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    urlName: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    slug: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    btnTitle: {
      type: String,
      default: ''
    }
  },
  components: {},
  data() {
    return {}
  },
  methods: {}
}
