import {mapGetters, mapActions} from "vuex";

import VueAdsPagination, {VueAdsPageButton} from 'vue-ads-pagination';
import cardVideo from '@/components/cards/card-video/index.vue'
import emptyData from '@/components/empty-data/index.vue'

export default {
  name: 'videos',
  components: {
    VueAdsPagination,
    VueAdsPageButton,
    cardVideo,
    emptyData
  },
  data() {
    return {};
  },
  beforeUpdate() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  },
  watch: {
    '$route.query.page': {
      immediate: true,
      handler: function (page) {
        if (page !== undefined) {
          this.fetchVideosList({skip: (this.$route.query.page - 1) * this.videosLimit})
        } else {
          this.$router.replace({name: 'videos', query: {page: 1}}).catch(() => {
            console.log();
          });
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      videosList: `videos/videosList`,
      isLoadingVideos: `videos/isLoadingVideos`,
      videosMeta: `videos/videosMeta`,
      videosLimit: `videos/videosLimit`,
    }),
    getPage() {
      return parseInt(this.$route.query.page) - 1;
    },
  },
  methods: {
    ...mapActions({
      fetchVideosList: `videos/VIDEOS_LIST_GET`,
    }),
    changePage(page) {
      this.$router.replace({name: 'videos', query: {page: parseInt(page) + 1}}).catch(() => {
        console.log()
      });
      window.scroll({top: 0, left: 0, behavior: 'smooth'});
    },
  },
};
